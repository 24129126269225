const MuiFormHelperText = {
  styleOverrides: {
    root: {
      fontSize: '0.75rem',
      transition: 'height 2s',
    },
  },
};

export default MuiFormHelperText;
