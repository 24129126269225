import palette from './palette';

const typography = {
  fontSize: 16,
  fontFamily: 'Inter, Arial, sans-serif',
  h1: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '4.75rem',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '3.5rem',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '2.5rem',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '2rem',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.1rem',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '1rem',
    fontWeight: 400,
  },
  subtitle2: {
    color: palette.text.secondary,
    fontSize: '0.8rem',
    fontWeight: 400,
    lineHeight: 1.35,
  },
  body1: {
    color: palette.text.primary,
    fontSize: '1rem',
  },
  body2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '14px',
  },
  button: {
    color: palette.text.primary,
    fontSize: '0.9rem',
    fontWeight: 500,
    textTransform: 'none',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '14px',
    letterSpacing: '0.33px',
    lineHeight: '15px',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '0.6rem',
    fontWeight: 600,
    letterSpacing: '0.33px',
    lineHeight: '15px',
  },
};

export default typography;
