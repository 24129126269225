import { transparentize } from 'polished';

const white = '#FFFFFF';
const black = '#000000';
const lightgray = '#CED5E2';

const palette = {
  black,
  white,
  lightgray,
  primary: {
    contrastText: white,
    main: '#0A60B0',
    dark: '#063E71',
    light: '#E7EFF7',
  },
  secondary: {
    contrastText: white,
    main: '#56B884',
    light: '#EDFAF4',
  },
  error: {
    contrastText: white,
    main: '#D52941',
  },
  warning: {
    contrastText: white,
    main: '#ECA72C',
  },
  info: {
    contrastText: white,
    main: '#008EED',
  },
  success: {
    contrastText: white,
    main: '#558811',
    light: '#EDFAF4',
    transparentize: '#EDFAF4',
  },
  text: {
    primary: '#000B12',
    secondary: transparentize(0.38, '#000B12'),
    placeholder: '#5f6368',
    link: '#0A60B0',
    footerMain: '#ffffffb3',
    footerDescription: '#ffffff66',
    footerCopyright: '#9B9B9B',
  },
  background: {
    default: '#FFF',
    paper: white,
    footer: '#063E71',
    block: '#f9fafb',
    neutral: '#F7F7F7',
    table: '#0A60B0',
    card: '#ffffff33',
  },
  divider: '#DADCE0',
};

export default palette;
