import '@fontsource/inter/latin-400.css';
import '@fontsource/inter/latin-500.css';
import '@fontsource/inter/latin-600.css';
import '@fontsource/inter/latin-700.css';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { Provider as FormStateProvider } from './src/hooks/useFormContext';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import theme from './src/theme';

export const wrapRootElement = ({ element }) => {
  return (
    <FormStateProvider>
      <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
        {element}
      </SnackbarProvider>
    </FormStateProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  );
};
