import palette from '../palette';

const MuiAccordion = {
  styleOverrides: {
    root: {
      width: '100%',
      boxShadow: 'none',
      borderTop: `1px solid ${palette.divider}`,
      paddingTop: 8,
      paddingBottom: 8,
      ':last-child': {
        borderBottom: `1px solid ${palette.divider}`,
      },
    },
  },
};

export default MuiAccordion;
