import MuiButton from './MuiButton';
import MuiLink from './MuiLink';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiInputLabel from './MuiInputLabel';
import MuiSelect from './MuiSelect';
import MuiFormHelperText from './MuiFormHelperText';
import MuiCheckbox from './MuiCheckbox';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiTypography from './MuiTypography';
import MuiAccordion from './MuiAccordion';
import MuiAccordionSummary from './MuiAccordionSummary';

const components = {
  MuiButton,
  MuiLink,
  MuiFormControlLabel,
  MuiInputLabel,
  MuiSelect,
  MuiFormHelperText,
  MuiCheckbox,
  MuiOutlinedInput,
  MuiTypography,
  MuiAccordion,
  MuiAccordionSummary,
};

export default components;
