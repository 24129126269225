import palette from '../palette';

const MuiAccordionSummary = {
  styleOverrides: {
    root: {
      fontSize: '1.1rem',
      color: palette.primary.main,
      fontWeight: 500,
    },
  },
};

export default MuiAccordionSummary;
